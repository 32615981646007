"use server";

import { getRequestEvent } from "solid-js/web";
import {
  getAllConversations,
  getPlutoCredits,
  getUserProfile,
} from "../apis/server_apis";
import { ChatDetails, Credits } from "../types/pluto";
import { getCookie } from "vinxi/http";
import { Cookie } from "~/types";
import { redirect } from "@solidjs/router";
import { UserProfile } from "../types/user";

export type PlutoRouteData = {
  conversations?: ChatDetails[];
  credits?: Credits;
  userName?: string;
};

export const getPlutoRouteData = async (): Promise<PlutoRouteData> => {
  const requestEvent = getRequestEvent();
  let conversations: ChatDetails[] | undefined;
  let credits: Credits | undefined;
  let userProfile: UserProfile | undefined;

  if (requestEvent) {
    let sid =
      requestEvent?.locals?.sid ??
      getCookie(requestEvent?.nativeEvent, Cookie.SessionId);

    if (!sid) {
      throw redirect("/gifts/login");
    } else {
      conversations = await getAllConversations();
      credits = await getPlutoCredits();
      userProfile = await getUserProfile();
    }
  }

  return {
    conversations,
    credits,
    userName: `${userProfile?.firstName} ${userProfile?.lastName}`,
  };
};
